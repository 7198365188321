import * as React from 'react';
import { useRouter } from 'next/router';
import Select from 'react-select';
import { OptionItem } from 'Client/types';
import { addNewTopFilter, getTopFiltersFromUrl } from 'Pages/dashboard/utils';
import { mapProposals, ALL_PROPOSALS } from './utils';
import {
  ProposalDropDownProps,
  ProposalOptionItem,
  ArrayOfOptions,
} from './types';
import {
  customOptionComponent,
  customMenuComponent,
  formatGroupLabel,
} from './components';
import { Wrapper } from './ProposalDropDown.styles';
import { onSelectChange } from './utils/onSelectChange';
import { customValueContainerComponent } from './components/CustomComponents';

export const ProposalDropDown: React.FC<ProposalDropDownProps> = ({
  proposals,
  isLoading,
  page,
}: ProposalDropDownProps) => {
  const router = useRouter();
  const topFilters = getTopFiltersFromUrl({ router });
  const proposalFilter = topFilters?.proposals || [];

  const [proposalsOptions, setProposalsOptions] =
    React.useState<ProposalOptionItem[]>();
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [selectedProposals, setSelectedProposals] =
    React.useState<Array<OptionItem>>(null);

  const onApplyFilters = () => {
    const proposalSlug = (() => {
      const selectedProposalsValues = selectedProposals.map(
        (option) => option.value
      );
      if (selectedProposalsValues.includes(ALL_PROPOSALS))
        return [ALL_PROPOSALS];
      const proposalsSelected = proposals
        .map((proposal) => {
          if (selectedProposalsValues.includes(proposal.title)) {
            return proposal.slug;
          }
        })
        .filter(Boolean);

      return proposalsSelected;
    })();
    addNewTopFilter({
      page,
      router,
      newFilter: { proposals: proposalSlug },
    });
    setIsMenuOpen(false);
  };

  const Option = customOptionComponent();
  const Menu = customMenuComponent({ onApplyFilters });
  const ValueContainer = customValueContainerComponent();

  React.useEffect(() => {
    if (!proposals) return;
    const options = mapProposals(proposals);
    const defaultValues = (() => {
      switch (true) {
        case options[1].options.length > 0:
          return options[1].options as OptionItem[];
        case options[3].options.length > 0:
          return options[3].options as OptionItem[];
        case options[2].options.length > 0:
          return options[2].options as OptionItem[];
        default:
          return [];
      }
    })();
    const { selectedProposals, applyFilter } = (() => {
      if (!proposalFilter.length)
        return { selectedProposals: defaultValues, applyFilter: true };
      if (proposalFilter.includes(ALL_PROPOSALS))
        return {
          selectedProposals: [options[0]?.options[0] as OptionItem],
          applyFilter: false,
        };

      const proposalsSelected = proposals
        .map((proposal) => {
          if (proposalFilter.includes(proposal.slug)) {
            return {
              value: proposal.title,
              label: proposal.title,
            };
          }
        })
        .filter(Boolean);
      if (!proposalsSelected || !proposalsSelected.length)
        return { selectedProposals: defaultValues, applyFilter: true };

      return { selectedProposals: proposalsSelected, applyFilter: false };
    })();
    setProposalsOptions(options);
    setSelectedProposals(selectedProposals);

    if (applyFilter && page === 'contributions') {
      const proposalSlug = (() => {
        if (!selectedProposals[0]) {
          return [];
        }

        const selectedProposalsValues = selectedProposals.map(
          (option) => option.value
        );
        if (selectedProposalsValues.includes(ALL_PROPOSALS))
          return [ALL_PROPOSALS];
        const proposalsSelected = proposals
          .map((proposal) => {
            if (selectedProposalsValues.includes(proposal.title)) {
              return proposal.slug;
            }
          })
          .filter(Boolean);

        return proposalsSelected;
      })();

      if (proposalSlug.length <= 0) return;

      addNewTopFilter({
        page,
        router,
        newFilter: { proposals: proposalSlug },
      });
    }
  }, [proposals]);

  return (
    <Wrapper>
      <Select
        data-onboarding="dashboard-proposal-filter-dropdown"
        options={proposalsOptions}
        onChange={(options: ArrayOfOptions, context: unknown) => {
          onSelectChange({
            actionContext: context,
            options,
            setSelectValue: setSelectedProposals,
          });
        }}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        classNamePrefix="react-select"
        value={selectedProposals}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect={false}
        openMenuOnClick={true}
        autoFocus={true}
        blurInputOnSelect={false}
        isMulti
        isLoading={isLoading}
        loadingMessage={() => 'fetching proposals...'}
        isClearable={false}
        isSearchable={true}
        components={{ Option, Menu, ValueContainer }}
        formatGroupLabel={formatGroupLabel}
      />
    </Wrapper>
  );
};
