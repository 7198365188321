import { mapValuesToOptions } from 'Pages/dashboard/contributions/utils';
import {
  UnMappedProposals,
  ProposalOptionItem,
  CustomOptionItem,
} from '../types';

export const ALL_PROPOSALS = 'All tiles';

export const mapProposals = (
  unMappedProposals: Array<UnMappedProposals>
): ProposalOptionItem[] => {
  const mappedProposals = [
    { label: '', options: mapValuesToOptions([ALL_PROPOSALS]) },
    { label: 'Active tiles', options: [] },
    { label: 'Draft tiles', options: [] },
    { label: 'Past tiles', options: [] },
    { label: 'No answers to display', options: [] },
  ];
  unMappedProposals.forEach((proposal) => {
    const proposalToOption = mapValuesToOptions([proposal.title]);
    if (!proposal.contributionsCount) {
      const proposalOption = proposalToOption[0] as unknown as CustomOptionItem;
      proposalOption.isDisabled = true;
      const newNoAnswersProposalsOptions = [
        ...mappedProposals[4].options,
        proposalOption,
      ];
      mappedProposals[4].options = newNoAnswersProposalsOptions;
      return;
    }
    if (proposal.stage === 'active') {
      const newActiveOptions = [
        ...mappedProposals[1].options,
        ...proposalToOption,
      ];
      mappedProposals[1].options = newActiveOptions;
      return;
    }
    if (proposal.stage === 'draft') {
      const newDraftOptions = [
        ...mappedProposals[2].options,
        ...proposalToOption,
      ];
      mappedProposals[2].options = newDraftOptions;
      return;
    }
    const newPastOptions = [...mappedProposals[3].options, ...proposalToOption];
    mappedProposals[3].options = newPastOptions;
  });
  return mappedProposals;
};
