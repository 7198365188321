import * as React from 'react';
import Select from 'react-select';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { addDays } from 'date-fns';
import { DateRange } from 'Shared/types/dashboard';
import { useProject } from 'Client/utils/hooks';
import { OptionItem } from 'Client/types';
import { addNewTopFilter, getTopFiltersFromUrl } from 'Pages/dashboard/utils';
import { mapValuesToOptions } from 'Pages/dashboard/contributions/utils';
import { DateRangePicker } from 'Atoms/DateRangePicker/DateRangePicker';
import { DateFilterProps } from './types';
import { mapDateFilter } from './utils';
import {
  predefinedOptions,
  WHOLE_PROJECT,
  SPECIFY_DATES,
  LAST_3_MONTH,
} from './predefinedOptions';
import { Wrapper } from './DateFilter.styles';
import {
  DateDisplay,
  customMenuComponent as menuComponent,
  customOptionComponent as optionComponent,
} from './components/';
import { GET_LAST_CONTRIBUTION, GET_LAST_RESPONDENT } from './DateFilter.gql';
import { wholeProjectDatesFn } from './utils/mapDateFilter';

const dateSetter = (dateValue, key, setFn) => {
  setFn((previous) => {
    const newDateObj = { ...previous };
    return { ...newDateObj, [key]: dateValue };
  });
};

export const DateFilter: React.FC<DateFilterProps> = ({
  appliedFilters,
  pageType,
}: DateFilterProps) => {
  const router = useRouter();
  const topFilters = getTopFiltersFromUrl({ router });
  const project = useProject();
  const { _id: projectId, id: projectName, stage, features } = useProject();
  const dateTopFilter = topFilters.date || null;
  const todayWithoutTime = new Date(new Date().setHours(0, 0, 0, 0));
  const isVisitorsPage = pageType === 'visitors';
  const isProjectTesting = stage === 'testing';

  const options = mapValuesToOptions(predefinedOptions, isVisitorsPage);
  const defaultOption = options.find((option) =>
    isVisitorsPage
      ? option.value === LAST_3_MONTH
      : option.value === WHOLE_PROJECT
  );

  const [dateFilter, setDateFilter] = React.useState<DateRange>({
    from: new Date(),
    to: new Date(),
  });
  const [currentMonth, setCurrentMonth] =
    React.useState<Date>(todayWithoutTime);
  const [selectedDateSpan, setSelectedDateSpan] =
    React.useState<OptionItem>(defaultOption);
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState<boolean>(false);
  const [isCalendarHovered, setIsCalendarHovered] =
    React.useState<boolean>(false);
  const [lastDate, setLastDate] = React.useState<Date>(null);

  const queryDetails = (() => {
    if (pageType === 'contributions') {
      return {
        query: GET_LAST_CONTRIBUTION,
        variables: {
          mostRecentContributionInput: {
            projectId,
            filters: appliedFilters,
          },
        },
      };
    }
    if (pageType === 'respondents') {
      return {
        query: GET_LAST_RESPONDENT,
        variables: {
          getMostRecentRespondentInput: {
            projectName,
            projectId,
            showOnlyRespondentsWithContributions:
              features?.showOnlyRespondentsWithContributions,
            filters: appliedFilters,
          },
        },
      };
    }

    return null;
  })();

  const { data, loading } =
    !!queryDetails &&
    useQuery(queryDetails.query, {
      variables: queryDetails.variables,
    });

  const onApplyFilters = () => {
    const dateFilterForUrl = [
      dateFilter.from.toUTCString(),
      dateFilter.to.toUTCString(),
    ].filter(Boolean);
    addNewTopFilter({
      page: pageType,
      router,
      newFilter: { date: dateFilterForUrl },
    });
    setIsMenuOpen(false);
    setIsCalendarOpen(false);
  };

  const openCalendarView = () => setIsCalendarOpen(true);
  const closeCalendarView = () => setIsCalendarOpen(false);

  const onSelectChange = (option: OptionItem) => {
    setSelectedDateSpan(option);
    const { value } = option;
    if (value === SPECIFY_DATES) {
      openCalendarView();
      return;
    }
    const dates = mapDateFilter(value as string, project);
    setDateFilter(dates);
    closeCalendarView();
    return;
  };

  const onMenuOpen = () => {
    setIsMenuOpen(true);
    if (selectedDateSpan.value === SPECIFY_DATES) openCalendarView();
  };
  const onMenuClose = () => {
    if (isCalendarHovered) return;
    setIsMenuOpen(false);
    closeCalendarView();
  };

  const CustomMenuComponent = menuComponent({ onApplyFilters });
  const CustomOptionComponent = optionComponent();

  React.useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      if (pageType === 'contributions') {
        const date = data.getMostRecentContribution
          ? data.getMostRecentContribution.date
          : new Date();
        setLastDate(new Date(date));
        return;
      }
      const date = data.getMostRecentRespondent
        ? parseInt(data.getMostRecentRespondent.signedUpDate)
        : new Date();
      setLastDate(new Date(date));
    }
  }, [data, pageType]);

  React.useEffect(() => {
    if (!queryDetails) setLastDate(new Date());
    if (dateTopFilter && dateTopFilter.from && dateTopFilter.to) {
      const specifyDatesOption = options.find(
        (option) => option.value === SPECIFY_DATES
      );
      const urlDateFilter = {
        from: addDays(new Date(dateTopFilter.from), 1),
        to: addDays(new Date(dateTopFilter.to), 1),
      };
      setDateFilter(urlDateFilter);
      setSelectedDateSpan(specifyDatesOption);
      return;
    }
    const defaultDates = isVisitorsPage
      ? mapDateFilter(defaultOption.value as string, project)
      : wholeProjectDatesFn(project);
    setDateFilter(defaultDates);
    addNewTopFilter({
      page: pageType,
      router,
      newFilter: { date: [defaultDates.from, defaultDates.to] },
    });
  }, []);

  return (
    <Wrapper data-testid="DateFilter-Wrapper">
      <Select
        isDisabled={isProjectTesting}
        value={selectedDateSpan}
        options={options}
        placeholder="Date filter"
        onChange={onSelectChange}
        classNamePrefix="react-select"
        menuIsOpen={isMenuOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        components={{
          Menu: CustomMenuComponent,
          Option: CustomOptionComponent,
        }}
        closeMenuOnSelect={false}
      />
      {isCalendarOpen && (
        <DateRangePicker
          dateFrom={dateFilter.from}
          dateTo={dateFilter.to}
          currentMonth={currentMonth}
          setDateFrom={(dateFrom: Date) =>
            dateSetter(dateFrom, 'from', setDateFilter)
          }
          isDashboard
          setDateTo={(dateTo: Date) => dateSetter(dateTo, 'to', setDateFilter)}
          setCurrentMonth={setCurrentMonth}
          setIsCalendarHovered={setIsCalendarHovered}
        />
      )}
      <DateDisplay
        lastDate={lastDate}
        dateRange={dateFilter}
        selectedOption={selectedDateSpan.value as string}
        isLoading={loading}
      />
    </Wrapper>
  );
};
