import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 10rem;
`;

export const TableContainer = styled.div`
  border-spacing: 0;
  &.sticky {
    overflow: scroll;
    .header {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .footer {
      bottom: 0;
      box-shadow: 0rem -0.1rem 0.1rem #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [role='rowgroup'] {
      [role='row'] {
        &:hover {
          div {
            background: ${({ theme }) =>
              theme.colorMappings.dashboardTableRowsHover};
          }
        }
      }
    }

    [data-sticky-td] {
      position: sticky !important;
    }

    [data-sticky-last-left-td] {
      box-shadow: 0.1rem 0rem 0.1rem #ccc;
      text-align: center;
    }

    [data-sticky-first-right-td] {
      box-shadow: -0.1rem 0rem 0.1rem #ccc;
    }
  }
`;

export const TableHeaders = styled.div`
  tr {
    :last-child {
      margin: 0;
      th,
      td {
        border-bottom: 0.1rem solid;
        border-bottom-color: ${({ theme }) => theme.colors.grey[700]};
      }
    }
  }
`;

export const EmptyTableView = styled.div`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15.625rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;

export const LoadMoreButton = styled.button`
  ${({ theme }) => css`
    background: none;
    color: ${theme.colors.green['A700']};
    padding: 0.5rem 1rem;
    border-radius: 0.1875rem;
    border: 1px solid ${theme.colors.green[500]};
    &:hover {
      cursor: pointer;
      background: ${theme.colors.green[100]};
    }
  `}
`;
