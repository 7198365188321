import { OptionItem } from 'Client/types';
import { WHOLE_PROJECT } from '../../components/DateFilter/predefinedOptions';

export const mapValuesToOptions = (
  values: string[] | number[] | OptionItem[],
  isVisitorsPage?: boolean
): OptionItem[] => {
  return values
    .map((value) => {
      if (isVisitorsPage && value === WHOLE_PROJECT) return;
      if (typeof value === 'object') {
        return { value: value.value, label: value.label };
      }

      return { value, label: value as string };
    })
    .filter(Boolean);
};
