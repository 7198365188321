import { mapValuesToOptions } from 'Pages/dashboard/contributions/utils';
import { ALL_PROPOSALS } from './mapProposals';
import {
    onSelectChangeProps,
    GetSelectedValuesProps,
    ReactSelectContext,
} from '../types';

export const getSelectedValues = ({
    context,
    typedOptions,
}: GetSelectedValuesProps) => {
    const { action, option } = context;
    const checkForAllProposals = (option) => option.value === ALL_PROPOSALS;
    if (action === 'select-option') {
        if (option.value === ALL_PROPOSALS) {
            const filteredOptions = typedOptions.filter(checkForAllProposals);
            return filteredOptions;
        }
        const containsAllProposals = typedOptions.find(checkForAllProposals);
        if (containsAllProposals) {
            const filteredOptions = typedOptions.filter(
                (option) => option.value !== ALL_PROPOSALS
            );
            return filteredOptions;
        }
    }
    return typedOptions;
};

export const onSelectChange = ({
    actionContext,
    options,
    setSelectValue,
}: onSelectChangeProps) => {
    if (!options.length) {
        const AllProposalsOptions = mapValuesToOptions([ALL_PROPOSALS]);
        setSelectValue(AllProposalsOptions);
        return;
    }
    const filteredOptions = getSelectedValues({
        context: actionContext as ReactSelectContext,
        typedOptions: options,
    });
    setSelectValue(filteredOptions);
};
